import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var numToStr = Number.prototype.toString;

var tryNumberObject = function tryNumberObject(value) {
  try {
    numToStr.call(value);
    return true;
  } catch (e) {
    return false;
  }
};

var toStr = Object.prototype.toString;
var numClass = "[object Number]";

var hasToStringTag = _shams();

exports = function isNumberObject(value) {
  if (typeof value === "number") {
    return true;
  }

  if (typeof value !== "object") {
    return false;
  }

  return hasToStringTag ? tryNumberObject(value) : toStr.call(value) === numClass;
};

export default exports;